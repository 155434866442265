<template>
  <span>
    <slot />
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          x-small
          v-bind="attrs"
          v-on="on"
        >
          <v-icon color="blue lighten-1">
            mdi-earth
          </v-icon>
        </v-btn>
      </template>
      <div>
        clé de traduction: {{ translationKey }}
      </div>
      <div
        v-for="(translation, index) in translations"
        :key="index"
      >
        {{ translation.locale }}: {{ translation.value }}
      </div>
    </v-tooltip>
  </span>
</template>

<script>
export default {
  name: 'LocalizedTextInfo',
  props: {
    translationKey: {
      type: String,
      required: true,
    },
    translations: {
      type: Array,
      required: true,
    },
  },
}
</script>

<template>
  <div>
    <div class="d-flex align-center">
      <v-text-field
        :value="item.name"
        label="Composition"
        @input="changeValue"
      />
      <LocalizedText
        v-if="item.localized_name"
        :translation-key="item.localized_name.translation_key"
        :translations="item.translations"
      />
    </div>
  </div>
</template>

<script>
import inputMixin from '@/mixins/input'
import LocalizedText from '@/components/core/LocalizedTextInfo.vue'

export default {
  components: { LocalizedText },
  mixins: [ inputMixin ],
  props: {
    value: {
      type: Array,
      required: false,
      default: null,
    },
    kind: {
      type: String,
      required: false,
      default: null,
    },
    required: Boolean,
  },
  computed: {
    item: {
      get () { return this.value
        ?.filter(attribute => attribute.kind === this.kind)?.[0] ?? {}
      },
    },
  },
  methods: {
    changeValue(value) {
      let attributes = this.value.filter((v) => v.kind !== this.kind)

      if (value?.trim()) {

        attributes = [
          ...attributes,
          {
            name: value,
            kind: this.kind,
          },
        ]
      }
      this.$emit('input', attributes)
    },
  },
}
</script>

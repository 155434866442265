<template>
  <v-card
    class="mb-4"
  >
    <v-card-title v-if="showTitle">
      {{ $t('views.products.forms.titles.product_features') }}
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="4"
          sm="12"
          xs="12"
        >
          <TargetSelector
            v-model="form.target"
            :error.sync="errors.target"
            required
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="12"
          xs="12"
        >
          <CategoryInput
            v-model="form.categoryId"
            :error.sync="errors.categoryId"
            required
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="12"
          xs="12"
        >
          <FamilySelector
            v-model="form.family"
            :error.sync="errors.family"
            required
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="12"
          xs="12"
        >
          <AttributeSelector
            v-model="form.attributes"
            kind="brand"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="12"
          xs="12"
        >
          <AttributeSelector
            v-model="form.attributes"
            kind="state"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="12"
          xs="12"
        >
          <AttributeSelector
            v-model="form.attributes"
            kind="color"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="12"
          xs="12"
        >
          <AttributeSelector
            v-model="form.attributes"
            kind="material"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="12"
          xs="12"
        >
          <AttributeTextInput
            v-model="form.attributes"
            kind="composition"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="12"
          xs="12"
        >
          <AttributeSelector
            v-model="form.attributes"
            kind="look"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="12"
          xs="12"
        >
          <AttributeSelector
            v-model="form.attributes"
            kind="years"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="12"
          xs="12"
        >
          <AttributeSelector
            v-model="form.attributes"
            kind="pattern"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="12"
          xs="12"
        >
          <AttributeSelector
            v-model="form.attributes"
            kind="style"
          />
        </v-col>
        <v-col
          v-if="!updateMode"
          cols="12"
          md="4"
          sm="12"
          xs="12"
        >
          <StoreSelector
            v-model="form.storeId"
            :error.sync="errors.storeId"
            required
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="12"
          xs="12"
        >
          <WeightInput
            v-model="form.weight"
            :category="form.categoryId"
            :error.sync="errors.weight"
            required
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="12"
          xs="12"
        >
          <DropsSelector
            v-model="form.dropIds"
            :error.sync="errors.dropIds"
          />
        </v-col>

        <v-col
          cols="12"
          md="4"
          sm="12"
          xs="12"
        >
          <TagSelector
            v-model="form.tagIds"
            :error.sync="errors.tagIds"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import AttributeSelector from '@/components/base/Product/Input/AttributeSelector.vue'
import AttributeTextInput from '@/components/base/Product/Input/AttributeTextInput.vue'
import CategoryInput from '@/components/base/Product/Input/CategoryInput'
import DropsSelector from '@/components/base/Product/Input/DropsSelector'
import FamilySelector from '@/components/base/Product/Input/FamilySelector'
import StoreSelector from '@/components/base/StoreSelector.vue'
import TagSelector from '@/components/base/Product/Input/TagSelector'
import TargetSelector from '@/components/base/Product/Input/TargetSelector.vue'
import WeightInput from '@/components/base/Product/Input/WeightInput'

export default {
  components: {
    AttributeTextInput,
    AttributeSelector,
    TargetSelector,
    CategoryInput,
    DropsSelector,
    TagSelector,
    FamilySelector,
    StoreSelector,
    WeightInput,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    showTitle: Boolean,
    updateMode: Boolean,
    errors: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

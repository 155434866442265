<template>
  <v-dialog
    :value="openedValue"
    max-width="500px"
    @click:outside="close()"
    @keydown.esc="close()"
  >
    <v-card
      class="pa-5"
      :loading="loading"
    >
      <v-card-text>
        <v-row dense>
          <v-col cols="6">
            <v-text-field
              v-model="inventory.stock"
              disabled
              label="Stock"
              type="number"
              step="1"
              hide-details
              clearable
              dense
              outlined
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6">
            <v-text-field
              v-model="inventory.sold_quantity"
              disabled
              label="Vendu"
              type="number"
              step="1"
              hide-details
              clearable
              dense
              outlined
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6">
            <v-text-field
              v-model="availableQuantityEdit"
              label="Disponible"
              type="number"
              step="1"
              hide-details
              clearable
              dense
              outlined
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          outlined
          color="red darken-2"
          @click="close()"
        >
          <v-icon left>
            mdi-cancel
          </v-icon>Annuler
        </v-btn>
        <v-spacer />
        <v-btn
          :disabled="submitDisabled"
          raised
          color="green darken-1"
          class="white--text"
          @click="save()"
        >
          <v-icon left>
            mdi-check-outline
          </v-icon>Enregistrer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import snackbarMixin from '@/mixins/snackbar'

export default {
  name: 'EditInventoryModal',
  mixins: [snackbarMixin],
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    inventoryId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      inventory: {},
      availableQuantityEdit: null,
    }
  },
  computed: {
    openedValue: {
      get () { return this.opened  },
      set (value) { this.$emit('update:opened', value) },
    },
    submitDisabled () {
      return Object.keys(this.inventory).length === 0 ||
          parseInt(this.availableQuantityEdit) === this.inventory.available_quantity
    },
  },
  watch: {
    inventoryId () {
      this.load()
    },
    inventory () {
      this.availableQuantityEdit = this.inventory?.available_quantity || null
    },
  },
  mounted() {
    if (this.inventoryId) {
      this.load()
    }
  },
  methods: {
    close() {
      this.loading = false
      this.openedValue = false
      this.availableQuantityEdit = this.inventory.available_quantity
    },
    async load () {
      this.loading = true

      try {
        const response = await this.$axios.get('logistic/inventories/' + encodeURIComponent(this.inventoryId))
        this.inventory = response.data ?? {}
      } finally {
        this.loading = false
      }
    },
    async save () {
      this.loading = true

      try {
        const response = await this.$axios.post(
          'logistic/inventories/' + encodeURIComponent(this.inventoryId) + '/adjust_available_quantity',
          {
            quantity: parseInt(this.availableQuantityEdit),
            version: this.inventory.version,
          }
        )
        this.inventory = response.data ?? {}
        this.snackbarSuccess(this.$i18n.t('views.stocks.messages.save_succeed'))

        this.$emit('success', this.inventory)

      } catch (error) {
        let error_message = error?.response?.data?.['hydra:description'] ?? 'internal'
        this.snackbarError(this.$i18n.t('views.stocks.messages.save_failed', { error: error_message }))
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

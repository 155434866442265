import { render, staticRenderFns } from "./AttributeSelector.vue?vue&type=template&id=68ecdac2&scoped=true&"
import script from "./AttributeSelector.vue?vue&type=script&lang=js&"
export * from "./AttributeSelector.vue?vue&type=script&lang=js&"
import style0 from "./AttributeSelector.vue?vue&type=style&index=0&id=68ecdac2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68ecdac2",
  null
  
)

export default component.exports